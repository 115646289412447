<template>
  <div>
    <form-wizard
      @on-complete="onComplete"
      @on-loading="setLoading"
      @on-error="handleErrorMessage"
      class="wizard"
      shape="circle"
      color="#2dabe2"
      error-color="#ff6961"
      title="Nouveau projet"
      :subtitle="subtitle"
      ref="wizard"
    >
      <!-- Project type -->
      <tab-content
        v-if="projectTypes.length > 1"
        title="Type de projet"
        icon="ti-menu-alt"
      >
        <label class="tab-content-label">Choisir le type de projet : </label>
        <b-form-group class="text-center">
          <b-form-radio-group
            v-model="projectType"
            @change="handleProjectTypeChange"
            :options="projectTypes"
            buttons
            stacked
            button-variant="outline-secondary"
          ></b-form-radio-group>
        </b-form-group>
      </tab-content>
      <!-- Informations client -->
      <tab-content
        title="Info. contact"
        icon="ti-user"
        :before-change="validateClientInfo"
      >
        <label class="tab-content-label">Informations de client : </label>
        <!-- Nom -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Nom *"
          label-for="input-last-name"
        >
          <b-form-input
            v-model="form.lastName"
            id="input-last-name"
            size="sm"
          ></b-form-input>
        </b-form-group>
        <!-- Prénom -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Prénom *"
          label-for="input-first-name"
        >
          <b-form-input
            v-model="form.firstName"
            id="input-first-name"
            size="sm"
          ></b-form-input>
        </b-form-group>
        <!-- E-mail -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="E-mail *"
          label-for="input-mail"
        >
          <b-form-input
            v-model="form.email"
            type="email"
            id="input-mail"
            size="sm"
          ></b-form-input>
        </b-form-group>
        <!-- Téléphone -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Téléphone *"
          label-for="input-phone"
        >
          <input
            class="input-phone"
            v-model="form.phone"
            id="input-phone"
            size="sm"
            spellcheck="false"
            maxlength="10"
            @input="
              form.phone = form.phone
                .replace(/[^0-9]/g, '')
                .replace(/(\..*)\./g, '$1')
            "
          />
        </b-form-group>
        <!-- Code postal -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Code postal *"
          label-for="input-cp"
        >
          <b-form-input
            v-model="form.codePostal"
            id="input-cp"
            size="sm"
            type="number"
          ></b-form-input>
        </b-form-group>
        <!-- Ville -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Ville *"
          label-for="input-city"
        >
          <b-form-input
            v-model="form.city"
            id="input-city"
            size="sm"
          ></b-form-input>
        </b-form-group>
        <!-- Address -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Adresse *"
          label-for="input-address"
        >
          <b-form-input
            v-model="form.address"
            id="input-address"
            size="sm"
          ></b-form-input>
        </b-form-group>
      </tab-content>
      <!-- Informations client de naissance -->
      <tab-content
        title="Info. naissance"
        icon="ti-layout-cta-left"
        :before-change="validateClientInfoNaissance"
      >
        <label class="tab-content-label">Informations de client : </label>
        <!-- Nom de naissance -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Nom de naissance"
          label-for="input-birth-name"
        >
          <b-form-input
            v-model="form.nom_naissance"
            id="input-last-birth-name"
            size="sm"
          ></b-form-input>
        </b-form-group>
        <!-- Date de naissance -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Date de naissance"
          label-for="input-birthday"
        >
          <b-form-input
            v-model="form.date_naissance"
            type="date"
            id="input-birthday"
            size="sm"
          ></b-form-input>
        </b-form-group>
        <!-- Ville de naissance -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Lieu de naissance"
          label-for="input-birth_city"
        >
          <b-form-input
            v-model="form.city_naissance"
            id="input-birth_city"
            size="sm"
          ></b-form-input>
        </b-form-group>
      </tab-content>
      <!-- Informations fiscales -->
      <!-- <tab-content
        title="Info. fiscales"
        icon="ti-money"
        :before-change="validateFiscalInfo"
      >
        <label class="tab-content-label">Informations fiscales : </label>
        Numéro fiscal
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Numéro fiscal *"
          label-for="input-num-fiscal-1"
        >
          <b-form-input
            v-model="form.tax_number"
            id="input-num-fiscal-1"
            size="sm"
          ></b-form-input>
        </b-form-group>
        Numéro fiscal 2
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Numéro fiscal 2"
          label-for="input-num-fiscal-2"
        >
          <b-form-input
            v-model="form.tax_number_2"
            id="input-num-fiscal-2"
            size="sm"
          ></b-form-input>
        </b-form-group>
        Ref.fiscal de l'avis
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Ref. fiscal de l'avis *"
          label-for="input-ref-fiscal"
        >
          <b-form-input
            v-model="form.reference_fiscal"
            id="input-ref-fiscal"
            size="sm"
          ></b-form-input>
        </b-form-group>
        Revenu fiscal
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Revenu fiscal *"
          label-for="input-rev-fiscal"
        >
          <b-form-input
            v-model="form.revenu_fiscal"
            id="input-rev-fiscal"
            size="sm"
            type="number"
          ></b-form-input>
        </b-form-group>
        Année
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Année *"
          label-for="input-fiscal-year"
        >
          <b-form-input
            v-model="form.year"
            id="input-fiscal-year"
            size="sm"
            type="number"
          ></b-form-input>
        </b-form-group>
      </tab-content> -->
      <!-- RDV AMO -->
      <tab-content
        title="RDV AMO"
        icon="ti-calendar"
        :before-change="validateRdvAmoTech"
      >
        <!-- Agence -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Filiale"
          label-for="input-filiale"
        >
          <b-form-select
            v-model="form.filiale"
            :options="getProjectsFilialeAgence || []"
            @change="changeFiliale($event)"
            value-field="id"
            text-field="name"
            id="input-filiale"
            size="sm"
          ></b-form-select>
        </b-form-group>
        <!-- Agence -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Agence"
          label-for="input-agence"
        >
          <b-form-select
            v-model="form.agence"
            :options="getProjectRouteDynamic || []"
            @change="changeAgence($event)"
            value-field="id"
            text-field="name"
            id="input-agence"
            size="sm"
          ></b-form-select>
        </b-form-group>
        <!-- AMO  -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="AMO"
          label-for="input-amo"
        >
          <b-form-select
            v-model="form.amo"
            :options="[...optionAmo] || []"
            value-field="id"
            text-field="full_name"
            id="input-amo"
            size="sm"
          ></b-form-select>
        </b-form-group>
        <!-- Date RDV Le -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="RDV le"
          label-for="input-rdv-le"
        >
          <b-form-input
            v-model="form.dateRdv"
            type="datetime-local"
            id="input-rdv-le"
            size="sm"
          ></b-form-input>
        </b-form-group>
        <!-- Statut Relevé Technique -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Statut Relevé Technique"
          label-for="input-statut-relvee-tech"
        >
          <b-form-select
            v-model="form.statutReleveTech"
            :options="[
              { value: 'Planifié', text: 'Planifié' },
              { value: 'Confirmé', text: 'Confirmé' },
              { value: 'Réalisé', text: 'Réalisé' },
              { value: 'Annulé', text: 'Annulé' }
            ]"
            id="input-statut-relvee-tech"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </tab-content>
      <!-- Notes -->
      <tab-content
        title="Notes"
        icon="ti-comment-alt"
        :before-change="validateNote"
      >
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Catégories de projet"
          label-for="input-category"
        >
          <multiselect
            v-model="selectedCategory"
            :options="getListCategorieProjet || []"
            placeholder="Rechercher Catégories"
            @input="handlSelectedCategory"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="true"
            :preserve-search="true"
            label="name"
            track-by="id"
            :preselect-first="false"
            :max-height="100"
            size="sm"
          >
            <span slot="noResult">Aucun Catégorie trouvé.</span>
            <span slot="noOptions">Aucun Catégorie trouvé.</span>
          </multiselect>
        </b-form-group>
        <label class="tab-content-label">
          Vous pouvez ajouter un commentaire ici :
        </label>
        <!-- <EEditor ref="eeditor" v-model="commentToAdd" class="mb-3" /> -->
        <NoteMention ref="eeditor" v-model="commentToAdd" class="mb-3" />
      </tab-content>
      <div class="three-dots-loading" v-if="loadingWizard">
        Chargement en cours
      </div>
      <div v-if="errorMsg">
        <span class="error">{{ errorMsg }}</span>
      </div>
      <b-button variant="outline-secondary" slot="prev" size="sm">
        Précédent
      </b-button>
      <b-button variant="outline-secondary" slot="next" size="sm">
        Suivant
      </b-button>
      <b-button variant="success" slot="finish" size="sm">
        Enregistrer
      </b-button>
    </form-wizard>
    <!-- Confirmation create nouvelle projet existe  -->
    <b-modal
      no-close-on-backdrop
      ref="show-list-project"
      id="show-list-project"
      title="Liste des projets de contact existant"
      :hide-footer="true"
      scrollable
      hide-header-close
    >
      <div class="page-content margin-top">
        <label
          >Liste des projets de contact
          <span class="bold uppercase">
            {{ `${this.form.lastName} ${this.form.firstName}` }}
          </span>
          :
        </label>

        <div
          class="tabs flex"
          v-for="(projet, index) in getcheckDoubleProject"
          :key="index"
        >
          <div class="tab-button-contact bold border-right border-bottom">
            {{ typeProject }}
          </div>

          <router-link
            :to="'/projects/' + projet.id"
            target="_blank"
            class="link border-right border-bottom"
            :class="{ 'border-top': index === 0 }"
          >
            <span class="margin">{{ projet.contact_full_name }}</span>
          </router-link>
        </div>
      </div>
      <div class="message mt-1">
        <div
          v-if="getProjectsProcessing || getContactsProcessing"
          class="three-dots-loading"
        >
          Chargement en cours
        </div>
        <div v-if="getProjectsError" class="error">
          <ul v-if="Array.isArray(getProjectsError)">
            <li v-for="(e, index) in getProjectsError" :key="index">
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ getProjectsError }}</span>
        </div>
      </div>
      <div class="form-actions top">
        <b-button
          @click="hideModal('show-list-project')"
          variant="outline-secondary"
        >
          Fermer
        </b-button>
        <!-- <b-button @click="createDoubleProject" variant="success">
          Ajouter quand même le nouveau projet
        </b-button> -->
      </div>
    </b-modal>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { mapActions, mapGetters } from 'vuex'
// import EEditor from '../EEditor'
import NoteMention from '../noteMention/NoteMention'
import moment from 'moment'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  components: {
    FormWizard,
    TabContent,
    // EEditor,
    NoteMention
  },
  props: {
    enableFastForm: {
      default: false
    },
    currentType: {
      required: true
    }
  },
  data() {
    return {
      formType: '',
      subtitle: 'Formulaire complet',
      loadingWizard: false,
      errorMsg: null,
      form: {
        // contact
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        codePostal: '',
        city: '',
        address: '',

        nom_naissance: '',
        date_naissance: '',
        cp_naissance: '',
        city_naissance: '',
        // fiscal
        tax_number: '',
        tax_number_2: '',
        reference_fiscal: '',
        revenu_fiscal: '',
        year: '',

        //AMO
        filiale: null,
        agence: null,
        amo: '',
        dateRdv: '',
        statutReleveTech: ''
      },
      commentToAdd: '',
      selectedCategory: null,
      projectType: null,
      optionAmo: [],
      typeProject: null
    }
  },
  methods: {
    ...mapActions([
      'createProject',
      'addComment',
      'fetchProjectFilialeIDName',
      'fetchProjectAmoCommercialIte',
      'fetchProjectRouteDynamic',
      'fetchListCategorieProjets',
      'checkDoubleProject'
    ]),
    handlSelectedCategory(category) {
      this.selectedCategory = category
    },
    changeFiliale(id_filiale) {
      this.fetchProjectRouteDynamic('/api/teams/' + id_filiale + '/depots')
      this.form.agence = null
      this.form.amo = null
      this.optionAmo = []
    },
    async changeAgence(id_agence) {
      await this.fetchProjectAmoCommercialIte(id_agence)
      this.form.amo = null
      if (
        this.getProjectsUsersAmoCommIte &&
        this.getProjectsUsersAmoCommIte.length
      ) {
        this.optionAmo = this.getProjectsUsersAmoCommIte
      }
    },
    handleProjectTypeChange() {
      this.$refs.wizard.nextTab()
      this.resetProjectType()
    },
    async onComplete() {
      this.loadingWizard = true
      const project = {
        title: `${this.form.firstName} ${this.form.lastName}`,
        dtype_id: this.projectType,
        // copy address info from contact
        zipCode: this.form.codePostal,
        city: this.form.city,
        adresse: this.form.address
      }
      if (this.currentUser && this.currentUser.details) {
        if (
          this.currentUser.details.visibleDtypes &&
          this.currentUser.details.visibleDtypes.data
        ) {
          const projectTypes = this.currentUser.details.visibleDtypes.data
          const projectTypeConfig = projectTypes.find(
            item => item.dtype_id == this.projectType
          )
          const currentPipeline = this.getPipelinesPipelines.find(
            item => item.type.id == this.projectType && item.is_principal == 1
          )
          // add pipeline + stage + substage
          if (projectTypeConfig) {
            project.cpipeline_id = currentPipeline.id
            project.cstage_id = projectTypeConfig.cstage_id
            project.csubstage_id = projectTypeConfig.csubstage_id
          }
        }
      }
      // minimal setup for contact
      const contact = {
        first_name: this.form.firstName,
        last_name: this.form.lastName,
        emails: [{ email: this.form.email, typeE: '' }],
        phones: [{ number: this.form.phone, typeP: '' }],
        zipCode: this.form.codePostal,
        city: this.form.city,
        adresse: this.form.address
      }
      // *** contact ***
      contact.nom_naissance = this.form.nom_naissance
      contact.date_naissance = this.form.date_naissance
        ? moment(this.form.date_naissance, 'YYYY-MM-DD').format(
            'YYYY-MM-DD HH:mm:ss'
          )
        : this.form.date_naissance
      contact.city_naissance = this.form.city_naissance

      // contact.tax_number = this.form.tax_number
      // contact.tax_number_2 = this.form.tax_number_2
      // contact.reference_fiscal_avis = [
      //   {
      //     reference_fiscal: this.form.reference_fiscal,
      //     revenu_fiscal: this.form.revenu_fiscal,
      //     year: this.form.year
      //   }
      // ]
      // *** projet ***
      let date_time = this.form.dateRdv
        ? moment(this.form.dateRdv, 'YYYY-MM-DDTHH:mm').format(
            'YYYY-MM-DD HH:mm:ss'
          )
        : this.form.dateRdv
      project.team_comm_ite_id = this.form.filiale
      project.depot_comm_ite_id = this.form.agence
      project.commercial_itinerant = this.form.amo
      project.date_previsite = date_time
      project.hour_previsite = date_time
        ? moment(date_time.split(' ')[1], 'HH:mm:ss').format('HH:mm')
        : date_time
      project.statut_releve_technique = this.form.statutReleveTech
      project.contact = contact
      const response = await this.createProject({
        currentType: this.currentType,
        data: project
      })
      this.loadingWizard = false
      if (response) {
        // add comment
        if (
          this.commentToAdd &&
          this.getProjectsLastCreated &&
          this.selectedCategory
        ) {
          await this.addComment({
            comment: this.commentToAdd,
            project: this.getProjectsLastCreated,
            category_id:
              this.selectedCategory && this.selectedCategory.id
                ? this.selectedCategory.id
                : null
          })
        }
        this.$emit('created', this.getProjectsLastCreated)
      }
    },
    setLoading: function(value) {
      this.loadingWizard = value
    },
    handleErrorMessage: function(errorMsg) {
      this.errorMsg = errorMsg
    },
    resetProjectType() {
      this.form = {
        // contact
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        codePostal: '',
        city: '',
        address: '',

        nom_naissance: '',
        date_naissance: '',
        cp_naissance: '',
        city_naissance: '',
        // fiscal
        tax_number: '',
        tax_number_2: '',
        reference_fiscal: '',
        revenu_fiscal: '',
        year: '',

        //AMO
        agence: null,
        amo: '',
        dateRdv: '',
        statutReleveTech: ''
      }
    },

    // Validations
    validateClientInfo: function() {
      return new Promise((resolve, reject) => {
        if (!this.form.lastName) {
          reject('Le champs "Nom" est obligatoire')
        } else if (!this.form.firstName) {
          reject('Le champs "Prénom" est obligatoire')
        } else if (!this.form.email) {
          reject('Le champs "E-mail" est obligatoire')
        } else if (!this.validateEmail(this.form.email)) {
          reject('Le champs "E-mail" est invalide')
        } else if (!this.form.phone) {
          reject('Le champs "Téléphone" est obligatoire')
        } else if (!this.form.codePostal) {
          reject('Le champs "Code postal" est obligatoire')
        } else if (!this.validatePostalCode(this.form.codePostal)) {
          reject('Le champs "Code postal" est invalide')
        } else if (!this.form.city) {
          reject('Le champs "Ville" est obligatoire')
        } else {
          this.checkDoubleProject({
            first_name: this.form.firstName,
            last_name: this.form.lastName,
            type_id: this.projectType
          })
          this.$nextTick(() => {
            setTimeout(() => {
              if (
                this.getcheckDoubleProject &&
                this.getcheckDoubleProject.length
              ) {
                resolve(true)
                // this.$nextTick(() => {
                // this.$refs.wizard.prevTab()
                this.typeProject = this.projectTypes.find(
                  item => item.value == this.projectType
                ).text
                this.$refs['show-list-project'].show()
                // })
              } else {
                resolve(true)
              }
            }, 1000)
          })
        }
      })
    },
    validateClientInfoNaissance: function() {
      return new Promise(resolve => {
        resolve(true)
      })
    },
    async createDoubleProject() {
      const project = {
        title: `${this.form.firstName} ${this.form.lastName}`,
        dtype_id: this.projectType
      }
      if (this.currentUser && this.currentUser.details) {
        if (
          this.currentUser.details.visibleDtypes &&
          this.currentUser.details.visibleDtypes.data
        ) {
          const projectTypes = this.currentUser.details.visibleDtypes.data
          const projectTypeConfig = projectTypes.find(
            item => item.dtype_id == this.projectType
          )
          const currentPipeline = this.getPipelinesPipelines.find(
            item => item.type.id == this.projectType && item.is_principal == 1
          )
          // add pipeline + stage + substage
          if (projectTypeConfig) {
            project.cpipeline_id = currentPipeline.id
            project.cstage_id = projectTypeConfig.cstage_id
            project.csubstage_id = projectTypeConfig.csubstage_id
          }
        }
      }
      const contact = {
        first_name: this.form.firstName,
        last_name: this.form.lastName
      }
      project.contact = contact

      const response = await this.createProject({
        currentType: this.currentType,
        data: project
      })
      if (response) {
        this.$emit('created', this.getProjectsLastCreated)
      }
    },
    hideModal(ref) {
      if (ref && this.$refs[ref]) {
        this.$refs[ref].hide()
      }
    },
    validateFiscalInfo() {
      return new Promise((resolve, reject) => {
        if (!this.form.tax_number) {
          reject('Le champs "Numéro fiscal" est obligatoire')
        } else if (!this.form.reference_fiscal) {
          reject('Le champs "Ref. fiscal de l\'avis" est obligatoire')
        } else if (!this.form.revenu_fiscal) {
          reject('Le champs "Revenu fiscal" est obligatoire')
        } else if (!this.form.year) {
          reject('Le champs "Année" est obligatoire')
        } else {
          resolve(true)
        }
      })
    },
    validateRdvAmoTech: function() {
      return new Promise((resolve, reject) => {
        if (!this.form.amo && this.form.agence) {
          reject('Le champs "AMO" est obligatoire')
        } else {
          resolve(true)
        }
      })
    },
    validateNote() {
      return new Promise((resolve, reject) => {
        if (!this.selectedCategory && this.commentToAdd) {
          reject('Le champs "Catégories de projet" est obligatoire')
        } else if (this.selectedCategory && !this.commentToAdd) {
          reject('Le champs commentaire est obligatoire')
        } else {
          resolve(true)
        }
      })
    },
    // Validations functions
    validateEmail(value) {
      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        String(value).toLowerCase()
      )
    },
    validatePostalCode(value) {
      return /^([0-9]{5})$/.test(value)
    }
  },
  computed: {
    ...mapGetters([
      'getProjectsTypes',
      'getProjectsLastCreated',
      'getPipelinesPipelines',
      'currentUser',
      'getProjectsFilialeAgence',
      'getProjectsUsersAmoCommIte',
      'getProjectRouteDynamic',
      'getListCategorieProjet',
      'getcheckDoubleProject',
      'getProjectsProcessing',
      'getContactsProcessing',
      'getProjectsError'
    ]),
    projectTypes: function() {
      return this.getProjectsTypes.map(type => ({
        text: type.name,
        value: type.id
      }))
    }
  },
  mounted() {
    if (this.getProjectsTypes && this.getProjectsTypes.length) {
      this.projectType = this.getProjectsTypes[0].id
    }
    this.fetchProjectFilialeIDName()
    this.fetchListCategorieProjets()
  }
}
</script>

<style lang="scss">
.wizard {
  .tab-content-label {
    font-weight: 600;
    position: relative;
    &:before {
      content: '';
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: #5e5e5e;
      display: block;
      position: absolute;
      left: -10px;
      top: 8px;
    }
  }
  .input-phone {
    border: 1px solid #ced4da;
    width: 100%;
    height: 32px;
  }
  .error {
    color: #ff6961;
    font-size: 14px;
  }
  .wizard-icon-circle,
  .ProseMirror {
    &:focus {
      outline: unset;
    }
  }
}
</style>
<style lang="scss" scoped>
#show-list-project {
  .tabs {
    .tab-button-contact {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px 0;
      width: 47%;
      background-color: #e5e5e5;
      border: 1px solid #d5d5d5;
      color: #5e5e5e;
      font-size: 12px;
      text-align: center;
    }
    .link {
      color: #5e5e5e;
      display: flex;
      align-items: center;
      padding: 4px 0;
      width: 100%;
      background-color: #ffffff;
      color: #5e5e5e;
      cursor: pointer;

      &:hover {
        color: #5e5e5e;
        background-color: transparent;
        text-decoration: none;
        font-weight: bold;
      }
      .margin {
        margin-left: 5px;
      }
    }
  }
  .flex {
    display: flex;
  }
  .uppercase {
    text-transform: uppercase;
  }
  .bold {
    font-weight: bold;
    font-size: 15px;
  }
  .border-top {
    border-top: 1px solid #dee2e6;
  }
  .top {
    margin-top: 16px;
  }
}
</style>
